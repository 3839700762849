import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Status } from 'src/utils/enums';

interface IWorkState {
  works: IWork[];
  status: Status;
  workSearchTerm: string;
}

const initialState: IWorkState = {
  works: [],
  status: Status.Idle,
  workSearchTerm: '',
};

const worksSlice = createSlice({
  name: 'works',
  initialState,
  reducers: {
    setWorks: (state, action: PayloadAction<IWork[]>) => {
      state.works = action.payload;
    },
    setWorksStatus: (state, action: PayloadAction<Status>) => {
      state.status = action.payload;
    },
    setWorkSearchTerm: (state, action: PayloadAction<string>) => {
      state.workSearchTerm = action.payload;
    },
  },
});

export const { setWorks, setWorksStatus, setWorkSearchTerm } = worksSlice.actions;

export default worksSlice.reducer;
