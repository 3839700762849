import { Link } from 'react-router-dom';

import { BriefcaseBusiness, Building, Menu } from 'lucide-react';

import { Button } from './ui/button';
import { Sheet, SheetContent, SheetTrigger } from './ui/sheet';

export default function AdminNavigationMenu() {
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant='outline' size='icon' className='shrink-0 md:hidden'>
          <Menu className='h-5 w-5' />
          <span className='sr-only'>Toggle navigation menu</span>
        </Button>
      </SheetTrigger>
      <SheetContent side='left' className='flex flex-col'>
        <nav className='grid gap-2 text-lg font-medium'>
          <Link
            to='/admin/brands'
            className='mx-[-0.65rem] flex items-center gap-4 rounded-xl px-3 py-2 text-muted-foreground hover:text-foreground'>
            <Building className='h-5 w-5' />
            Brands
          </Link>
          <Link
            to='/admin/works'
            className='mx-[-0.65rem] flex items-center gap-4 rounded-xl px-3 py-2 text-muted-foreground hover:text-foreground'>
            <BriefcaseBusiness className='h-5 w-5' />
            Works
          </Link>
        </nav>
      </SheetContent>
    </Sheet>
  );
}
