import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth';
import { toast } from 'sonner';
import { Button } from 'src/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'src/components/ui/form';
import { Input } from 'src/components/ui/input';
import { auth } from 'src/helpers/firebase';
import { getDocumentService } from 'src/helpers/firebase/documentServices';
import { z } from 'zod';

const FormSchema = z.object({
  email: z.string().email(),
  password: z.string().optional(),
});

export default function Admin() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    try {
      await signInWithEmailAndPassword(auth, data.email, data.password || '');
      toast.success('Başarıyla giriş yapıldı');
    } catch (error: any) {
      toast.error('Geçersiz e-posta veya şifre');
    }
  }

  async function onForgotPassword(data: z.infer<typeof FormSchema>) {
    try {
      await sendPasswordResetEmail(auth, data.email);
      toast.success('Sıfırlama epostası gönderildi');
    } catch (error: any) {
      toast.error('Sıfırlama Epostası gönderilemedi');
    }
  }

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if (user) {
        getDocumentService('users', user.uid || '').then(
          (res: { id: string; isAdmin?: boolean }) => {
            res.isAdmin && navigate('/admin/works');
          }
        );
      } else {
        setLoading(false);
      }
    });
  }, [navigate]);

  if (loading) return <div>Loading...</div>;

  return (
    <div className='h-screen w-screen flex justify-center items-center'>
      <div className='w-96'>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-4'>
            <FormField
              control={form.control}
              name='email'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input placeholder='Enter Email' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name='password'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <Input placeholder='Enter Password' {...field} type='password' />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className='flex items-center justify-between'>
              <Button type='submit'>Sign In</Button>
              <Button onClick={form.handleSubmit(onForgotPassword)} variant='ghost'>
                Forgot Password?
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
}
