import React from 'react';

import InputField from './InputField';
import SubmitButton from './SubmitButton';
import TextArea from './TextArea';

interface ContactFormProps {
  title: string;
}

const ContactForm: React.FC<ContactFormProps> = ({ title }) => {
  const fields = [
    { name: 'name', label: 'Name' },
    { name: 'surname', label: 'Surname' },
    { name: 'email', label: 'Email' },
  ];

  return (
    <form className='flex flex-col px-6 py-4 text-base border border-solid rounded-[var(--sds-size-radius-200)] max-md:px-5'>
      <div className='text-3xl font-bold leading-10 max-md:max-w-full'>{title}</div>
      {fields.map(field => (
        <InputField key={field.name} name={field.name} label={field.label} />
      ))}
      <TextArea name='message' label='Message' />
      <SubmitButton />
    </form>
  );
};

export default ContactForm;
