import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import { toast } from 'sonner';
import { db } from 'src/helpers/firebase';

export const deleteDocumentService = async (collection: string, docId: string) => {
  const docRef = doc(db, collection, docId);

  try {
    toast.info('Deleting document...');
    const res = await deleteDoc(docRef);
    toast.success('Document deleted successfully!');
    return res;
  } catch (error) {
    toast.error('Error deleting document!');
    throw error;
  }
};

export const setDocumentService = async (docId: string, collectionName: string, data: any) => {
  try {
    const res = await setDoc(doc(collection(db, collectionName), docId), data);
    return { id: docId, res };
  } catch (error) {
    throw error;
  }
};

export const getDocumentService = async (collection: string, docId: string) => {
  const docRef = doc(db, collection, docId);

  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() };
    } else {
      throw new Error('No such document!');
    }
  } catch (error) {
    throw error;
  }
};

export const getDocumentsService = async (collectionName: string) => {
  try {
    const querySnapshot = await getDocs(collection(db, collectionName));
    const docs = querySnapshot.docs.map(doc => {
      return { id: doc.id, ...doc.data() };
    });

    return docs;
  } catch (error) {
    console.log('Error getting documents: ', error);
    throw error;
  }
};

export const getDocumentsByUpperDocumentIdService = async (
  upperCollectionName: string,
  collectionName: string,
  filterByPropertyName: string,
  upperDocumentId: string
) => {
  const documentsRef = collection(db, collectionName);
  const upperDocRef = doc(db, upperCollectionName, upperDocumentId);
  const q = query(documentsRef, where(filterByPropertyName, '==', upperDocRef));

  try {
    const querySnapshot = await getDocs(q);
    const docs = querySnapshot.docs.map(doc => {
      return { id: doc.id, ...doc.data() };
    });

    return docs;
  } catch (error) {
    throw error;
  }
};

export const setBatchDocumentsService = async (collectionName: string, data: any[]) => {
  const batch = writeBatch(db);
  const collectionRef = collection(db, collectionName);

  data.forEach(item => {
    const docRef = doc(collectionRef, item.id);
    const { id, ...rest } = item;
    batch.set(docRef, rest);
  });

  try {
    await batch.commit();
    console.log('Batch commit successful!');
  } catch (error) {
    console.error('Error batch commit: ', error);
    throw error;
  }
};
