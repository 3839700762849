import ContactForm from 'src/components/Form/ContactForm';
import InfoSection from 'src/components/InfoSection';

export default function Contact() {
  return (
    <>
      <main className='container m-auto'>
        <div className='flex flex-col text-xl font-bold border bg-border'>
          <iframe
            title='map'
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1504.0323836064877!2d29.01105305374226!3d41.06757689476863!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab709d6a06465%3A0x92be9fab147509f3!2sALESTA%20P%26PP!5e0!3m2!1str!2str!4v1721134906815!5m2!1str!2str'
            height='450'
          />
          <InfoSection
            address='Esentepe, Kasap Sk. Eser İş Merkezi No:20 C Blok Kat:1 Daire:18, 34394 Şişli/İstanbul'
            email='infosrcalesta3p.com'
          />
        </div>
      </main>
      <div className='m-auto container mt-8'>
        <ContactForm title='For your questions and suggestions' />
      </div>
    </>
  );
}
