import { Link } from 'react-router-dom';

import { BriefcaseBusiness, Building } from 'lucide-react';

export default function AdminSideMenu() {
  return (
    <div className='flex h-full max-h-screen flex-col gap-2'>
      <div className='flex h-14 items-center border-b px-4 lg:h-[60px] lg:px-6'>
        <Link to='/' className='flex items-center gap-2 font-semibold'>
          <span className=''>Alesta P&PP</span>
        </Link>
      </div>

      <div className='flex-1'>
        <nav className='grid items-start px-2 text-sm font-medium lg:px-4'>
          <Link
            to='/admin/brands'
            className='flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary'>
            <Building className='h-4 w-4' />
            Brands
          </Link>
          <Link
            to='/admin/works'
            className='flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary'>
            <BriefcaseBusiness className='h-4 w-4' />
            Works
          </Link>
        </nav>
      </div>
    </div>
  );
}
