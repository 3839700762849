import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { zodResolver } from '@hookform/resolvers/zod';
import { doc } from 'firebase/firestore';
import { toast } from 'sonner';
import { Button } from 'src/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'src/components/ui/form';
import { Input } from 'src/components/ui/input';
import { db } from 'src/helpers/firebase';
import { addImage } from 'src/helpers/firebase/customStorageServices';
import { setDocumentService } from 'src/helpers/firebase/documentServices';
import { addWorkAction } from 'src/store/actions/worksActions';
import { useAppSelector } from 'src/store/store';
import { toSlug } from 'src/utils/stringToSlug';
import { z } from 'zod';

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './ui/select';
import { Toggle } from './ui/toggle';

export default function AddWorkForm() {
  const [images, setImages] = useState<any[]>([]);
  const [posterImage, setPosterImage] = useState<any>();
  const brands = useAppSelector(state => state.brands.brands);
  const works = useAppSelector(state => state.works.works);

  const FormSchema = z.object({
    description: z.string().optional(),
    identity: z.string().min(1, { message: 'Identity is required.' }),
    isConfidential: z.boolean(),
    title: z.string().min(2, { message: 'Title must be at least 2 characters.' }),
    vimeoId: z.string().optional(),
    imageUrls: z.array(z.any()).optional(),
    brand: z.enum(['', ...brands.map(brand => brand.id)]),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      description: '',
      identity: '',
      isConfidential: false,
      title: '',
      vimeoId: '',
      imageUrls: [],
      brand: brands[0]?.id,
    },
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'image/*': ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp'] },
    onDrop: acceptedFiles => {
      const newImages = acceptedFiles;
      setImages([...images, ...newImages]);
    },
  });

  const removeImage = (url: string, event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setImages(images.filter(image => image !== url));
  };

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    let posterImageUrl;
    const isPosterImageChoosed = !!posterImage;

    if (data.brand === '') {
      toast.error('Please select a brand');
      return;
    }

    const workId = toSlug(data.brand + '-' + data.title);
    const biggestOrder = works.reduce((maxOrder, work) => {
      if (work.order && work.order > maxOrder) {
        return work.order;
      }

      return maxOrder;
    }, 0);

    const docRef = doc(db, 'Brands', data.brand);

    for (const file of images) {
      const url = await addImage('works/' + workId, file);

      const indexOfImage = images.indexOf(file);
      images[indexOfImage] = url;
    }

    if (isPosterImageChoosed) {
      posterImageUrl = await addImage('works/' + workId, posterImage);
    }

    const newWork = {
      ...data,
      id: workId,
      brand: docRef,
      order: biggestOrder + 1,
      imageUrls: images,
      posterImage: posterImageUrl,
    };

    setDocumentService(workId, 'Works', newWork).then(res => toast.success('Work added'));
    addWorkAction(newWork);
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className='w-full space-y-6'>
        <div className='grid grid-cols-4 gap-10'>
          <div className='col-span-1'>
            <div
              {...getRootProps()}
              className='border-dashed mb-4 p-2 rounded border-spacing-2 border-2'>
              <input {...getInputProps()} />
              <p>{`Drag 'n' drop some files here, or click to select files`}</p>
            </div>
            <div className='h-96 overflow-y-auto'>
              {images.map((image, index) => {
                const isFile = image instanceof File;
                const previewUrl = isFile ? URL.createObjectURL(image) : image;

                return (
                  <div key={index} className='mb-4'>
                    <img width={640} height={480} src={previewUrl} alt={`preview ${index}`} />
                    <Button
                      variant='outline'
                      className='my-2'
                      onClick={event => removeImage(image, event)}>
                      Remove
                    </Button>
                  </div>
                );
              })}
            </div>
          </div>
          <div className='col-span-3 flex flex-col gap-4'>
            <FormField
              control={form.control}
              name='brand'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Brand</FormLabel>
                  <FormControl>
                    <Select
                      value={field.value}
                      onValueChange={(value: string) => {
                        field.onChange(value);
                      }}>
                      <SelectTrigger className='w-[180px]'>
                        <SelectValue placeholder='Brand' />
                      </SelectTrigger>
                      <SelectContent>
                        {brands.map(brand => (
                          <SelectItem key={brand.id} value={brand.id}>
                            {brand.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormDescription>This is the brand of the content.</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='title'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Title</FormLabel>
                  <FormControl>
                    <Input placeholder='Enter title' {...field} />
                  </FormControl>
                  <FormDescription>This is the title of the content.</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='identity'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Identity</FormLabel>
                  <FormControl>
                    <ReactQuill
                      placeholder='Enter identity'
                      value={field.value}
                      onChange={value => field.onChange(value)}
                    />
                  </FormControl>
                  <FormDescription>{`This is your work's identity.`}</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='description'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Input placeholder='Enter description' {...field} />
                  </FormControl>
                  <FormDescription>This is a brief description of the content.</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormItem>
              <FormLabel className='flex items-center'>Poster Image</FormLabel>
              <FormControl>
                <Input
                  type='file'
                  accept='image/*'
                  placeholder='Choose Image'
                  onChange={event => setPosterImage(event.target.files?.[0])}
                />
              </FormControl>
              <FormDescription>
                This is the poster image of the content. It will be shown on the homepage.
              </FormDescription>
              <FormMessage />
            </FormItem>

            <div className='grid grid-cols-2 gap-10'>
              <FormField
                control={form.control}
                name='isConfidential'
                render={({ field }) => (
                  <FormItem className='flex flex-col justify-between pt-2'>
                    <FormLabel>Is Confidential</FormLabel>
                    <FormControl>
                      <Toggle
                        className='p-0 overflow-hidden'
                        pressed={field.value}
                        onPressedChange={value => {
                          field.onChange(value);
                        }}
                        variant='outline'>
                        <div
                          className={`h-full w-full ${field.value ? 'bg-yellow900 text-black' : ''} flex justify-center items-center transition-all duration-300`}>
                          confidential
                        </div>
                      </Toggle>
                    </FormControl>
                    <FormDescription>Is the content confidential?</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name='vimeoId'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Vimeo ID</FormLabel>
                    <FormControl>
                      <Input placeholder='Enter Vimeo ID' {...field} />
                    </FormControl>
                    <FormDescription>This is the Vimeo ID of the content.</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </div>

        <div className='flex w-full justify-end'>
          <Button type='submit'>Submit</Button>
        </div>
      </form>
    </Form>
  );
}
