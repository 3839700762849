import { useRef } from 'react';

import { IconMenu2 } from '@tabler/icons-react';
import HeaderTitles from 'src/components/HeaderTitles';
import SocialIcons from 'src/components/SocialIcons';
import { Separator } from 'src/components/ui/separator';
import { Sheet, SheetClose, SheetContent, SheetTrigger } from 'src/components/ui/sheet';

export default function Drawer() {
  const closeRef = useRef<any>(null);

  return (
    <Sheet>
      <SheetTrigger>
        <IconMenu2 size={28} />
      </SheetTrigger>
      <SheetContent className='w-screen flex flex-col justify-between items-center'>
        <div />
        <SheetClose ref={closeRef} onClick={() => closeRef.current.click()}>
          <HeaderTitles containerStyle='w-full flex-col text-center gap-4' titleStyle='text-xl' />
        </SheetClose>
        <div className='w-full flex flex-col gap-4'>
          <Separator />
          <SocialIcons />
        </div>
      </SheetContent>
    </Sheet>
  );
}
