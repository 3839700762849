import React from 'react';

interface InfoSectionProps {
  address: string;
  email: string;
}

const InfoSection: React.FC<InfoSectionProps> = ({ address, email }) => {
  return (
    <footer className='flex flex-col justify-center py-6 mt-1.5 w-full max-md:max-w-full'>
      <div className='flex flex-wrap gap-2.5 justify-center content-center px-4'>
        <div className='flex-1 leading-7'>{address}</div>
        <div className='flex-1 my-auto text-right text-amber-400 leading-[141%]'>{email}</div>
      </div>
    </footer>
  );
};

export default InfoSection;
