export function toSlug(string: string) {
  string = string.toLowerCase();

  const turkishMap: { [key: string]: string } = {
    ç: 'c',
    ı: 'i',
    ş: 's',
    ğ: 'g',
    ü: 'u',
    ö: 'o',
    Ç: 'C',
    İ: 'I',
    Ş: 'S',
    Ğ: 'G',
    Ü: 'U',
    Ö: 'O',
  };

  string = string.replace(/[çışğüöÇIŞĞÜÖ]/g, match => turkishMap[match] || match);

  string = string
    .normalize('NFKC') // Normalize Unicode characters
    .replace(/[\u0300-\u036f]/g, '') // Accent characters
    .replace(/[^a-z0-9\s-]/g, '') // Remove non-alphanumeric characters except spaces and hyphens
    .trim() // Trim leading and trailing spaces
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/-+/g, '-'); // Replace multiple hyphens with a single hyphen

  return string;
}
