import React from 'react';

interface TextAreaProps {
  name: string;
  label: string;
}

const TextArea: React.FC<TextAreaProps> = ({ name, label }) => {
  return (
    <div className='flex flex-col mt-6 whitespace-nowrap leading-[140%] max-md:max-w-full'>
      <label htmlFor={name} className='max-md:max-w-full'>
        {label}
      </label>
      <div className='flex flex-col pt-3 pb-1.5 pl-4 mt-2 border border-solid max-md:max-w-full'>
        <textarea
          id={name}
          name={name}
          className='max-md:mr-1.5 max-md:max-w-full bg-[color:var(--sds-color-background-default-default)]'
          placeholder={`Enter ${label}`}
        />
      </div>
    </div>
  );
};

export default TextArea;
