import { useState } from 'react';

interface ICoverImage {
  image: string;
  lowResImage?: string;
  className?: string;
}

export default function ImageWithLoading({ image, lowResImage, className }: ICoverImage) {
  const [loaded, setLoaded] = useState(false);

  const handleLoad = () => {
    setLoaded(true);
  };

  return (
    <div className={`relative overflow-hidden w-full h-full ${className}`}>
      {!loaded && lowResImage && (
        <img
          src={lowResImage}
          alt={'low resolution'}
          style={{
            position: 'absolute',
            inset: 0,
            width: '100%',
            height: '100%',
          }}
          className='h-full w-full object-contain'
          loading='lazy'
        />
      )}

      <img
        src={image}
        alt={'cover'}
        className={`h-full w-full object-contain ${loaded ? 'opacity-100' : 'opacity-0'}`}
        onLoad={handleLoad}
        loading='lazy'
      />
    </div>
  );
}
