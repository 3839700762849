import { getDocumentsService } from 'src/helpers/firebase/documentServices';
import { Status } from 'src/utils/enums';

import { setBrandSearchTerm, setBrands, setBrandsStatus } from '../slices/brandsSlice';
import { store } from '../store';

const dispatch = store.dispatch;

export const loadBrandsAction = async () => {
  dispatch(setBrandsStatus(Status.Loading));
  try {
    const brands: IBrand[] = await getDocumentsService('Brands');
    dispatch(setBrands(brands));
    dispatch(setBrandsStatus(Status.Success));
  } catch (error) {
    dispatch(setBrandsStatus(Status.Error));
  }
};

export const addBrandAction = async (brand: IBrand) => {
  try {
    const newBrands = [...store.getState().brands.brands, brand];
    dispatch(setBrands(newBrands));
    return newBrands;
  } catch (error) {
    throw error;
  }
};

export const updateBrandAction = async (brand: IBrand) => {
  try {
    const newBrands = store
      .getState()
      .brands.brands.map(item => (item.id === brand.id ? brand : item));
    dispatch(setBrands(newBrands));
    return newBrands;
  } catch (error) {
    throw error;
  }
};

export const deleteBrandAction = async (brandId: string) => {
  try {
    const newBrands = store.getState().brands.brands.filter(item => item.id !== brandId);
    dispatch(setBrands(newBrands));
    return newBrands;
  } catch (error) {
    throw error;
  }
};

export const setBrandSearchTermAction = (searchTerm: string) => {
  dispatch(setBrandSearchTerm(searchTerm));
};
