import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Status } from 'src/utils/enums';

interface IBrandsState {
  brands: IBrand[];
  status: Status;
  brandSearchTerm: string;
}

const initialState: IBrandsState = {
  brands: [],
  status: Status.Idle,
  brandSearchTerm: '',
};

const brandsSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {
    setBrands: (state, action: PayloadAction<IBrand[]>) => {
      state.brands = action.payload;
    },
    setBrandsStatus: (state, action: PayloadAction<Status>) => {
      state.status = action.payload;
    },
    setBrandSearchTerm: (state, action: PayloadAction<string>) => {
      state.brandSearchTerm = action.payload;
    },
  },
});

export const { setBrands, setBrandsStatus, setBrandSearchTerm } = brandsSlice.actions;

export default brandsSlice.reducer;
