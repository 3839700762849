import { useNavigate } from 'react-router-dom';

import { useTheme } from 'next-themes';
import SocialIcons from 'src/components/SocialIcons';

export default function Footer() {
  const { systemTheme } = useTheme();
  const navigate = useNavigate();

  return (
    <div className='flex flex-col gap-8 my-8 pt-6 pb-10 border-t'>
      <img
        onClick={() => navigate('/')}
        className='m-auto w-32 cursor-pointer'
        src={systemTheme === 'dark' ? '/alestaDark.png' : '/alestaLight.png'}
        alt='Logo'
      />
      <SocialIcons />
      <div className='text-xs text-center'>
        Alesta Production & Post Production - All rights reserved. / Tüm hakları saklıdır.
      </div>
    </div>
  );
}
