import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconUserShield } from '@tabler/icons-react';
import { useTheme } from 'next-themes';
import HeaderTitles from 'src/components/HeaderTitles';
import { Separator } from 'src/components/ui/separator';
import { auth } from 'src/helpers/firebase';
import { getDocumentService } from 'src/helpers/firebase/documentServices';
import Drawer from 'src/layouts/Drawer';

export default function Header() {
  const [isAdmin, setIsAdmin] = useState(false);
  const { systemTheme } = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if (user) {
        getDocumentService('users', user.uid || '').then(
          (res: { id: string; isAdmin?: boolean }) => {
            setIsAdmin(res.isAdmin || false);
          }
        );
      }
    });
  }, []);

  return (
    <>
      <header className='flex h-24 justify-between items-center container'>
        <img
          onClick={() => navigate('/')}
          src={systemTheme === 'dark' ? '/alestaDark.png' : '/alestaLight.png'}
          alt='Logo'
          className='w-32 cursor-pointer'
          width={100}
          height={100}
        />
        <HeaderTitles containerStyle='hidden md:flex' />
        <div className='w-32'>
          {isAdmin ? (
            <IconUserShield
              className='cursor-pointer'
              onClick={() => navigate('/admin')}
              size={24}
            />
          ) : null}
        </div>
        <div className='block md:hidden pr-4'>
          <Drawer />
        </div>
      </header>
      <Separator className='mb-10' />
    </>
  );
}
