import { Link } from 'react-router-dom';

import { IconBrandInstagram, IconBrandLinkedin, IconBrandVimeo } from '@tabler/icons-react';

interface ISocialIcons {
  style?: string;
  iconstStyle?: string;
}

export default function SocialIcons({ style, iconstStyle }: ISocialIcons) {
  return (
    <div className={`gap-4 flex justify-center ${style}`}>
      <Link to='https://www.instagram.com/alesta3p?igsh=MTUyamt1YWs2b2tidg==' target='_blank'>
        <IconBrandInstagram className={`cursor-pointer ${iconstStyle}`} size={24} />
      </Link>
      <IconBrandVimeo className={`cursor-pointer ${iconstStyle}`} size={24} />
      <Link to='https://www.linkedin.com/company/alesta3p' target='_blank'>
        <IconBrandLinkedin className={`cursor-pointer ${iconstStyle}`} size={24} />
      </Link>
    </div>
  );
}
