import { resizeImage } from 'src/utils/imageResulationDecreaser';

import { addFile, deleteFile, getFilePath, getFileUrl } from './storageServices';

export const addImage = async (path: string, file: any) => {
  const lowImage = await resizeImage(64, file);
  await addFile(path + '/s_' + file.name, lowImage);
  const res = await addFile(path + '/' + file.name, file);
  const url = getFileUrl(res.ref.fullPath);
  return url;
};

export const deleteImage = async (url: string) => {
  const path = decodeURIComponent(getFilePath(url));
  const lowPath = path.replace('/' + path.split('/').pop(), '/s_' + path.split('/').pop());
  await deleteFile(path);
  await deleteFile(lowPath);
};
