import { deleteObject, ref, uploadBytes } from 'firebase/storage';
import { storage } from 'src/helpers/firebase';

export const addFile = async (path: string, file: any) => {
  const storageRef = ref(storage, path);

  try {
    const res = await uploadBytes(storageRef, file);
    return res;
  } catch (error) {
    throw error;
  }
};

export const deleteFile = async (path: string) => {
  const storageRef = ref(storage, path);

  try {
    const res = await deleteObject(storageRef);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getFilePath = (url: string) => {
  const path = new URL(url).pathname;
  const pathArray = path.split('/');
  return pathArray.pop() || '';
};

export const getFileUrl = (path: string) => {
  return `https://firebasestorage.googleapis.com/v0/b/${storage.app.options.storageBucket}/o/${encodeURIComponent(path)}?alt=media`;
};
