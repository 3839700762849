import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import 'react-quill/dist/quill.snow.css';

import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'sonner';
import { Button } from 'src/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'src/components/ui/form';
import { Input } from 'src/components/ui/input';
import { addImage } from 'src/helpers/firebase/customStorageServices';
import { setDocumentService } from 'src/helpers/firebase/documentServices';
import { addBrandAction } from 'src/store/actions/brandsActions';
import { toSlug } from 'src/utils/stringToSlug';
import { z } from 'zod';

export default function AddBrandForm() {
  const [logo, setLogo] = useState<any>(null);

  const FormSchema = z.object({
    name: z.string().min(2, { message: 'Name must be at least 2 characters.' }),
    logoUrl: z.string(),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: '',
      logoUrl: '',
    },
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'image/*': ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp'] },
    onDrop: acceptedFiles => {
      const file = acceptedFiles[0];
      setLogo(file);
      form.setValue('logoUrl', URL.createObjectURL(file));
    },
  });

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    const brandId = toSlug(data.name);

    const url = await addImage('brands/' + brandId, logo);

    const newBrand = {
      id: brandId,
      name: data.name,
      logoUrl: url,
    };

    setDocumentService(brandId, 'Brands', newBrand).then(res => toast.success('Brand added'));
    addBrandAction(newBrand);
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className='w-full space-y-6'>
        <div className='grid grid-cols-2 gap-10'>
          <div className='col-span-1'>
            <div
              {...getRootProps()}
              className='border-dashed mb-4 p-2 rounded border-spacing-2 border-2'>
              <input {...getInputProps()} />
              <p>{`Drag 'n' drop the logo here, or click to select a logo file`}</p>
              {logo && (
                <img width={200} height={200} src={URL.createObjectURL(logo)} alt='Logo preview' />
              )}
            </div>
          </div>
          <div className='col-span-1'>
            <FormField
              control={form.control}
              name='name'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input placeholder='Enter name' {...field} />
                  </FormControl>
                  <FormDescription>This is the name of the brand.</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>

        <div className='flex w-full justify-end'>
          <Button type='submit'>Submit</Button>
        </div>
      </form>
    </Form>
  );
}
