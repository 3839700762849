import { Sheet, SheetContent, SheetTitle, SheetTrigger } from 'src/components/ui/sheet';

import EditBrandForm from './EditBrandForm';
import EditWorkForm from './EditWorkForm';

export default function EditDocumentDrawer({ type, data }: { type: string; data: IWork | IBrand }) {
  return (
    <Sheet>
      <SheetTrigger className='w-full'>
        <div className='hover:bg-muted relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50'>
          Edit
        </div>
      </SheetTrigger>
      <SheetContent
        side='bottom'
        className='w-screen flex flex-col items-center max-h-screen overflow-y-auto'>
        <SheetTitle>You are editing document</SheetTitle>
        {type === 'Works' ? (
          <EditWorkForm work={data as IWork} />
        ) : (
          <EditBrandForm brand={data as IBrand} />
        )}
      </SheetContent>
    </Sheet>
  );
}
