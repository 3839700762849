import { useEffect } from 'react';

import { Toaster } from './components/ui/sonner';
import RootApp from './navigation';
import { loadBrandsAction } from './store/actions/brandsActions';
import { loadWorksAction } from './store/actions/worksActions';
import { useAppSelector } from './store/store';
import { Status } from './utils/enums';

function App() {
  const brandStatus = useAppSelector(state => state.brands.status);
  const workStatus = useAppSelector(state => state.works.status);

  useEffect(() => {
    if (brandStatus === Status.Idle) {
      loadBrandsAction();
    }
    if (workStatus === Status.Idle) {
      loadWorksAction();
    }
  }, [brandStatus, workStatus]);
  return (
    <>
      <Toaster />
      <RootApp />
    </>
  );
}

export default App;
