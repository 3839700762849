import { useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'sonner';
import { Button } from 'src/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'src/components/ui/form';
import { Input } from 'src/components/ui/input';
import { setDocumentService } from 'src/helpers/firebase/documentServices';
import { updateBrandAction } from 'src/store/actions/brandsActions';
import { z } from 'zod';

const FormSchema = z.object({
  name: z.string().min(1, { message: 'Name is required.' }),
});

export default function InputBrandForm({ brand }: { brand: IBrand }) {
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: brand.name,
    },
  });

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    const newBrand = {
      ...brand,
      ...data,
    };

    setDocumentService(brand.id, 'Brands', newBrand).then(res => toast.success('Brands updated!'));
    updateBrandAction(newBrand);
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className='w-full space-y-6'>
        <FormField
          control={form.control}
          name='name'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input placeholder='Enter name' {...field} />
              </FormControl>
              <FormDescription>This is the name of the brand.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className='flex w-full justify-end'>
          <Button type='submit'>Submit</Button>
        </div>
      </form>
    </Form>
  );
}
