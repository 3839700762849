import React from 'react';

interface InputFieldProps {
  name: string;
  label: string;
}

const InputField: React.FC<InputFieldProps> = ({ name, label }) => {
  return (
    <div className='flex flex-col mt-6 whitespace-nowrap max-md:max-w-full'>
      <label htmlFor={name} className='leading-[140%] max-md:max-w-full'>
        {label}
      </label>
      <input
        type='text'
        id={name}
        name={name}
        className='justify-center px-4 py-3 mt-2 border border-solid bg-[color:var(--sds-color-background-default-default)] leading-[100%] rounded-[var(--sds-size-radius-200)] max-md:max-w-full'
        placeholder={`Enter ${label}`}
      />
    </div>
  );
};

export default InputField;
