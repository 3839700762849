import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { IconMenu2 } from '@tabler/icons-react';
import { MoreHorizontal } from 'lucide-react';
import { toast } from 'sonner';
import AddDocumentDrawer from 'src/components/AddDocumentDrawer';
import AdminNavigationMenu from 'src/components/AdminNavigationMenu';
import AdminSearchBar from 'src/components/AdminSearchBar';
import AdminSideMenu from 'src/components/AdminSideMenu';
import AdminUserMenu from 'src/components/AdminUserMenu';
import EditDocumentDrawer from 'src/components/EditDocumentDrawer';
import ImageWithLoading from 'src/components/ImageWithLoading';
import DeleteDocumentDrawer from 'src/components/RemoveDocumentDrawer';
import { Button } from 'src/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from 'src/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import { auth } from 'src/helpers/firebase';
import { setBatchDocumentsService } from 'src/helpers/firebase/documentServices';
import { lowResImageUrlExporter } from 'src/helpers/lowResImageUrlExporter';
import { setWorks } from 'src/store/slices/worksSlice';
import { store, useAppSelector } from 'src/store/store';

export default function AdminPage() {
  const navigate = useNavigate();
  const dispatch = store.dispatch;
  const { categorie } = useParams();
  const works = useAppSelector(state => state.works.works);
  const brands = useAppSelector(state => state.brands.brands);
  const workSearchTerm = useAppSelector(state => state.works.workSearchTerm);
  const brandSearchTerm = useAppSelector(state => state.brands.brandSearchTerm);

  const formattedCategorie = useMemo(
    () => (categorie === 'brands' ? 'Brands' : categorie === 'works' ? 'Works' : ''),
    [categorie]
  );

  const datas = useMemo(() => {
    const sortedWorks = [...works].sort((a, b) => (a.order || 1) - (b.order || 1));
    return categorie === 'brands'
      ? brands.filter(a => a.name?.toLowerCase().includes(brandSearchTerm))
      : sortedWorks.filter(a => a.title?.toLowerCase().includes(workSearchTerm));
  }, [categorie, brands, works, brandSearchTerm, workSearchTerm]);

  const tableHeads = useMemo(() => {
    if (datas.length === 0) return [];

    const sampleData =
      categorie === 'brands'
        ? (({ logoUrl, ...rest }: IBrand) => rest)(datas[0] as IBrand)
        : (({ imageUrls, brand, ...rest }: IWork) => rest)(datas[0] as IWork);

    return Object.keys(sampleData).sort();
  }, [datas, categorie]);

  const handleDragEnd = (result: any) => {
    if (workSearchTerm) {
      toast.error('Sıralama yapabilmek için arama yapmamalısınız!');
      return;
    }
    if (!result.destination) return;

    const updatedDatas = [...datas];
    const [movedData] = updatedDatas.splice(result.source.index, 1);
    updatedDatas.splice(result.destination.index, 0, movedData);

    const newDatas = updatedDatas.map((data, index) => ({
      ...data,
      order: index + 1,
    }));

    if (categorie === 'works') {
      dispatch(setWorks(newDatas as IWork[]));
      setBatchDocumentsService('Works', newDatas);
      toast.success('Sıralama başarılı!');
    }
  };

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if (!user) {
        navigate('/admin');
      }
    });
  }, [navigate]);

  return (
    <div className='grid min-h-screen w-full md:grid-cols-[220px_1fr] lg:grid-cols-[280px_1fr]'>
      <div className='hidden border-r bg-muted/40 md:block'>
        <AdminSideMenu />
      </div>
      <div className='flex flex-col'>
        <header className='flex h-14 items-center gap-4 border-b bg-muted/40 px-4 lg:h-[60px] lg:px-6'>
          <AdminNavigationMenu />
          <div className='w-full flex-1'>
            <AdminSearchBar type={formattedCategorie} />
          </div>
          <AddDocumentDrawer type={formattedCategorie} />
          <AdminUserMenu />
        </header>
        <main className='flex flex-col gap-4 p-4 lg:gap-6 lg:p-6'>
          <Card>
            <CardHeader>
              <CardTitle>{formattedCategorie}</CardTitle>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className='hidden w-[100px] sm:table-cell'>
                      <span className='sr-only'>Image</span>
                    </TableHead>
                    <TableHead className='w-[100px] sm:table-cell'>
                      <span className='sr-only'>Drag</span>
                    </TableHead>
                    {tableHeads.map((head, index) => (
                      <TableHead className='max-xl:even:hidden xl:table-cell' key={index}>
                        {head}
                      </TableHead>
                    ))}
                    <TableHead>
                      <span className='sr-only'>Actions</span>
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId='workList'>
                    {provided => (
                      <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                        {datas.map((data: IWork | IBrand, index) => (
                          <Draggable key={data.id} draggableId={data.id} index={index}>
                            {provided => (
                              <TableRow
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                key={data.id}>
                                <TableCell>
                                  <IconMenu2 />
                                </TableCell>
                                <TableCell className='hidden sm:table-cell'>
                                  <div className='aspect-square overflow-hidden rounded-lg max-w-16'>
                                    <ImageWithLoading
                                      image={
                                        categorie === 'brands' ? data.logoUrl : data.imageUrls?.[0]
                                      }
                                      lowResImage={
                                        categorie === 'brands'
                                          ? lowResImageUrlExporter(data.logoUrl)
                                          : lowResImageUrlExporter(data.imageUrls?.[0])
                                      }
                                    />
                                  </div>
                                </TableCell>
                                {tableHeads.map((head, headIndex) => (
                                  <TableCell
                                    className='max-xl:even:hidden xl:table-cell'
                                    key={headIndex}>
                                    {(() => {
                                      if (head === 'identity') {
                                        return (
                                          <span
                                            dangerouslySetInnerHTML={{ __html: data[head] || '' }}
                                          />
                                        );
                                      }
                                      if (head === 'isConfidential') {
                                        return data[head] ? 'Yes' : 'No';
                                      }

                                      if (head === 'id') {
                                        return datas[index].id;
                                      }
                                      return data[head];
                                    })()}
                                  </TableCell>
                                ))}
                                <TableCell>
                                  <DropdownMenu>
                                    <DropdownMenuTrigger asChild>
                                      <Button aria-haspopup='true' size='icon' variant='ghost'>
                                        <MoreHorizontal className='h-4 w-4' />
                                        <span className='sr-only'>Toggle menu</span>
                                      </Button>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent align='end'>
                                      <DropdownMenuLabel>Actions</DropdownMenuLabel>
                                      <EditDocumentDrawer type={formattedCategorie} data={data} />
                                      <DeleteDocumentDrawer
                                        type={categorie || 'works'}
                                        data={data}
                                      />
                                    </DropdownMenuContent>
                                  </DropdownMenu>
                                </TableCell>
                              </TableRow>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </CardContent>
          </Card>
        </main>
      </div>
    </div>
  );
}
