import { useState } from 'react';

import { AspectRatio } from '@radix-ui/react-aspect-ratio';

interface ICoverImage {
  brandImage?: string;
  image: string;
  lowResImage?: string;
  title: string;
  alt?: string;
  isConfidential: boolean;
  onPress?: () => void;
}

export default function CoverImage({
  brandImage,
  image,
  title,
  lowResImage,
  isConfidential,
  onPress,
}: ICoverImage) {
  const [loaded, setLoaded] = useState(false);

  const handleLoad = () => {
    setLoaded(true);
  };

  return (
    <>
      <AspectRatio
        onClick={onPress}
        className='group relative cursor-pointer overflow-hidden'
        ratio={16 / 9}>
        {isConfidential && (
          <div className='absolute bg-yellow900 -rotate-45 top-8 -left-9 px-8 text-sm font-semibold shadow-lg py-1 z-10 text-black'>
            CONFIDENTIAL
          </div>
        )}
        {!loaded && lowResImage && (
          <img
            src={lowResImage}
            alt={'cover'}
            style={{
              position: 'absolute',
              inset: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            className='h-full w-full object-cover'
          />
        )}
        <img
          src={image}
          alt={'cover'}
          className={`h-full w-full object-cover ${loaded ? 'opacity-100' : 'opacity-0'}`}
          onLoad={handleLoad}
          loading='lazy'
        />
        <div className='absolute inset-0 bg-gray-800 opacity-0 transition-opacity duration-300 group-hover:opacity-90' />
        <div className='absolute inset-0 flex flex-col items-center justify-center'>
          {brandImage && (
            <div className='h-32'>
              <img
                src={brandImage}
                alt='Logo'
                className='opacity-0 transition-opacity duration-300 group-hover:opacity-90 h-full w-full object-contain'
                width={100}
                height={100}
              />
            </div>
          )}
          <div className='select-none font-verdana text-lg text-transparent group-hover:text-white transition'>
            {title}
          </div>
        </div>
      </AspectRatio>
    </>
  );
}
