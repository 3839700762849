interface IVimeoVideoComponent {
  videoId: number;
}

export default function VimeoVideoComponent({ videoId }: IVimeoVideoComponent) {
  const formattedURL = `https://player.vimeo.com/video/${videoId}`;
  return (
    <div className='w-full h-full aspect-video'>
      <iframe
        height={'100%'}
        width={'100%'}
        allowFullScreen
        title='vimeo-player'
        src={formattedURL}
      />
    </div>
  );
}
