import { Search } from 'lucide-react';
import { setBrandSearchTerm } from 'src/store/slices/brandsSlice';
import { setWorkSearchTerm } from 'src/store/slices/worksSlice';
import { store, useAppSelector } from 'src/store/store';

import { Input } from './ui/input';

export default function AdminSearchBar({ type }: { type: string }) {
  const dispatch = store.dispatch;
  const brandSearchTerm = useAppSelector(state => state.brands.brandSearchTerm);
  const workSearchTerm = useAppSelector(state => state.works.workSearchTerm);

  return (
    <form>
      <div className='relative'>
        <Search className='absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground' />
        <Input
          value={type === 'Brands' ? brandSearchTerm : workSearchTerm}
          type='search'
          onChange={e => {
            if (type === 'brands') {
              dispatch(setBrandSearchTerm(e.target.value));
            } else {
              dispatch(setWorkSearchTerm(e.target.value));
            }
          }}
          placeholder={'search'}
          className='w-full appearance-none bg-background pl-8 shadow-none md:w-2/3 lg:w-1/3'
        />
      </div>
    </form>
  );
}
