import React from 'react';

const SubmitButton: React.FC = () => {
  return (
    <div className='flex flex-col justify-center mt-6 whitespace-nowrap leading-[100%] max-md:max-w-full'>
      <button
        type='submit'
        className='justify-center items-center p-3 border border-solid rounded-[var(--sds-size-radius-200)] max-md:px-5 max-md:max-w-full'>
        Submit
      </button>
    </div>
  );
};

export default SubmitButton;
