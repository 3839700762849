import VimeoVideoComponent from 'src/components/VimeoVideoComponent';
import { aboutText } from 'src/consts/texts';

export default function About() {
  return (
    <>
      <div className='font-light px-6 text-sm text-center max-w-2xl m-auto'>{aboutText}</div>
      <div className='container flex flex-col gap-10 mt-10'>
        <VimeoVideoComponent videoId={1} />
        <VimeoVideoComponent videoId={1} />
      </div>
    </>
  );
}
