import { getDocumentsService } from 'src/helpers/firebase/documentServices';
import { Status } from 'src/utils/enums';

import { setWorkSearchTerm, setWorks, setWorksStatus } from '../slices/worksSlice';
import { store } from '../store';

const dispatch = store.dispatch;

export const loadWorksAction = async () => {
  dispatch(setWorksStatus(Status.Loading));
  try {
    const works: IWork[] = await getDocumentsService('Works');
    const sortedWorks = works.sort((a, b) => (a.order || 1) - (b.order || 1));
    dispatch(setWorks(sortedWorks));
    dispatch(setWorksStatus(Status.Success));
  } catch (error) {
    dispatch(setWorksStatus(Status.Error));
  }
};

export const addWorkAction = async (work: IWork) => {
  try {
    const newWorks = [...store.getState().works.works, work];
    dispatch(setWorks(newWorks));
    return newWorks;
  } catch (error) {
    throw error;
  }
};

export const updateWorkAction = async (work: IWork) => {
  try {
    const newWorks = store.getState().works.works.map(item => (item.id === work.id ? work : item));
    dispatch(setWorks(newWorks));
    return newWorks;
  } catch (error) {
    throw error;
  }
};

export const deleteWorkAction = async (workId: string) => {
  try {
    const newWorks = store.getState().works.works.filter(item => item.id !== workId);
    dispatch(setWorks(newWorks));
    return newWorks;
  } catch (error) {
    throw error;
  }
};

export const setWorkSearchTermAction = (searchTerm: string) => {
  dispatch(setWorkSearchTerm(searchTerm));
};
