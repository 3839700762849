import { useNavigate, useParams } from 'react-router-dom';

import { IconCircleArrowLeft } from '@tabler/icons-react';
import Autoplay from 'embla-carousel-autoplay';
import CoverImage from 'src/components/CoverImage';
import WorkPageCarouselImage from 'src/components/ImageWithLoading';
import VimeoVideoComponent from 'src/components/VimeoVideoComponent';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from 'src/components/ui/carousel';
import { Skeleton } from 'src/components/ui/skeleton';
import { lowResImageUrlExporter } from 'src/helpers/lowResImageUrlExporter';
import { useAppSelector } from 'src/store/store';
import { Status } from 'src/utils/enums';

export default function WorkPage() {
  const { workId } = useParams();
  const navigate = useNavigate();
  const works = useAppSelector(state => state.works.works);
  const work = works.find(item => item.id === workId);
  const brands = useAppSelector(state => state.brands.brands);
  const brand = brands.find(item => item.id === work?.brand.id);
  const brandWorks = works
    .filter(item => item.brand.id === brand?.id)
    .filter(item => item.id !== work?.id);
  const otherBrandWorks = works.filter(item => item.brand.id !== brand?.id);
  const workStatus = useAppSelector(state => state.works.status);

  if (workStatus === Status.Loading || workStatus === Status.Idle) {
    return (
      <>
        <div className='flex flex-col gap-10'>
          <div className='work-container'>
            <Skeleton className='h-96 w-full' />
          </div>
          <Skeleton className='h-44 w-full' />
          <Skeleton className='h-44 w-full' />
        </div>
      </>
    );
  }

  return (
    <>
      <div className='work-container'>
        <IconCircleArrowLeft className='cursor-pointer' onClick={() => navigate('/')} size={42} />
      </div>

      <div className='work-container flex justify-between items-center'>
        <div>{work?.title}</div>
        <img src={brand?.logoUrl || '/AlestaPlaceholder.png'} alt='Logo' className='w-32 rounded' />
      </div>

      {(work?.vimeoId === '' || !work?.vimeoId) && (
        <div className='work-container mt-4'>
          <Carousel>
            <CarouselContent>
              {work &&
                work.imageUrls &&
                work.imageUrls.slice(work.posterImage ? 0 : 1).map((url, index) => (
                  <CarouselItem key={url}>
                    <WorkPageCarouselImage image={url} lowResImage={lowResImageUrlExporter(url)} />
                  </CarouselItem>
                ))}

              {work && work.imageUrls?.length === 0 && (
                <CarouselItem>
                  <div className='aspect-video'>
                    <WorkPageCarouselImage
                      image='/AlestaPlaceholder.png'
                      lowResImage={lowResImageUrlExporter('/AlestaPlaceholder.png')}
                    />
                  </div>
                </CarouselItem>
              )}
            </CarouselContent>
            <CarouselPrevious className='hidden md:flex text-yellow900 scale-100 md:scale-150' />
            <CarouselNext className='hidden md:flex text-yellow900 scale-100 md:scale-150' />
          </Carousel>
        </div>
      )}

      {work?.vimeoId && (
        <div className='work-container mt-4'>
          <VimeoVideoComponent videoId={Number(work?.vimeoId)} />
        </div>
      )}

      <div
        className='work-container mt-4'
        dangerouslySetInnerHTML={{
          __html: work?.identity || '',
        }}
      />

      <div className='work-container mt-4'>{work?.description}</div>

      {brandWorks.length > 0 && (
        <>
          <div className='work-container mt-10'>Other works from {brand?.name}</div>

          <Carousel
            plugins={[Autoplay({ delay: 2000, stopOnMouseEnter: true })]}
            opts={{ loop: true }}
            className='mt-2'>
            <CarouselContent>
              {brandWorks.map((item, index) => (
                <CarouselItem className='basis-1/2 md:basis-1/4 mx-auto' key={item.id}>
                  <CoverImage
                    onPress={() => navigate(`/work/${item.id}`)}
                    image={item.imageUrls?.[0] ? item.imageUrls[0] : ''}
                    title={item.title || ''}
                    isConfidential={item.isConfidential || false}
                  />
                </CarouselItem>
              ))}
            </CarouselContent>
          </Carousel>
        </>
      )}

      {otherBrandWorks.length > 0 && (
        <>
          <div className='work-container mt-10'>Other works from other brands</div>
          <Carousel
            plugins={[Autoplay({ delay: 2000, stopOnMouseEnter: true })]}
            opts={{ loop: true }}
            className='mt-2'>
            <CarouselContent>
              {otherBrandWorks.map((item, index) => (
                <CarouselItem className='basis-1/2 md:basis-1/4 mx-auto' key={item.id}>
                  <CoverImage
                    onPress={() => navigate(`/work/${item.id}`)}
                    image={item.imageUrls?.[0] ? item.imageUrls[0] : '/AlestaPlaceholder.png'}
                    title={item.title || ''}
                    isConfidential={item.isConfidential || false}
                  />
                </CarouselItem>
              ))}
            </CarouselContent>
          </Carousel>
        </>
      )}
    </>
  );
}
